import { defineComponent, PropType } from 'vue'
import './index.scss'
import {
  ArticleCard,
  ArticleCardWrapper,
  // GfrCarousel,
  // GfrCarouselSlide,
  GfrTitle,
  ModuleBase,
  Swiper,
  SwiperSlide
} from '#components'
import { moduleBaseProps } from '@/components/module/base'
interface NewsItem {
  title: string
  tag: string
  image: string
  publish_time: number
  weight: number
  url: string
  display: number // 前端过滤 0 ｜ 1
}
export default defineComponent({
  name: 'ModuleHomeScreen2',
  props: {
    ...moduleBaseProps,
    title: String,
    list: {
      type: Array as PropType<NewsItem[]>,
      default: () => []
    }
  },
  setup(props, { slots }) {
    const newsList = computed(() => {
      return props.list.filter((item) => item.display === 1).sort((a, b) => b.weight - a.weight)
    })
    return () => (
      <ModuleBase name={props.name} class={'module-home-news-base'}>
        <svg
          class={'module-home-news-decor desktop'}
          viewBox="0 0 1920 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M600.52 0C595.98 0 591.72 1.77 588.57 4.91L565.06 24.68H0V31.18V32.68H558.65L589.91 6.39C592.74 3.56 596.51 2 600.51 2H1920V0H600.52Z"
            fill="#FFBA00"
          />
        </svg>
        <svg
          class={'module-home-news-decor mob'}
          width="750"
          height="33"
          viewBox="0 0 750 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M131.52 -6.10352e-05C126.98 -6.10352e-05 122.72 1.76994 119.57 4.90994L96.06 24.6799H0V31.1799V32.6799H89.65L120.91 6.38994C123.74 3.55994 127.51 1.99994 131.51 1.99994H750V-6.10352e-05L131.52 -6.10352e-05Z"
            fill="#FFBA00"
          />
        </svg>
        <section class={['module-home-news']}>
          {props.title && (
            <GfrTitle level={2} class={'com-module-title'}>
              {props.title}
            </GfrTitle>
          )}
          <ArticleCardWrapper class={'module-home-news-list'}>
            {newsList.value?.slice(0, 3).map((item) => (
              <ArticleCard title={item.title} tag={item.tag} url={item.url} image={item.image} />
            ))}
          </ArticleCardWrapper>
          <Swiper
            class={'module-home-news-carousel'}
            modules={[SwiperAutoplay, SwiperPagination]}
            slides-per-view={1}
            loop={newsList.value.length > 1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true
            }}
          >
            {newsList.value?.slice(0, 3).map((item) => (
              <SwiperSlide>
                <ArticleCard title={item.title} tag={item.tag} url={item.url} image={item.image} />
              </SwiperSlide>
            ))}
          </Swiper>
          {slots.default?.()}
        </section>
      </ModuleBase>
    )
  }
})
